import React, { lazy, Suspense } from "react";

import { Box } from "@mui/material";
import { client } from "./config/apollo";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Loading from "./components/loading";

const Error = lazy(() => import("./pages/404"));
const Privilege = lazy(() => import("./pages/privilege"));
const Points = lazy(() => import("./pages/points"));

import "./css/style.css";

export default function Root(props) {
  return (
    <ApolloProvider client={client}>
      <Box p={2}>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="*" element={<Error />} />
              <Route path="/privilege/campaign" element={<Privilege />} />
              <Route path="/privilege/points" element={<Points />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </Box>
    </ApolloProvider>
  );
}
